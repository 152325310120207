body {
    margin: 0;
    padding: 0;
    color: #38393d;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
}

textarea, input, button, select {
    font-family: inherit;
    font-size: inherit;
}

.container {
    display: flex;
    margin: auto;
    padding: 25px;
    max-width: 780px;
    height: 738px;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.content {
    margin: 0 auto;
    width: 92%;
}

h2 {
    margin: auto;
    padding: 20px 0 22px;
    font-size: 34px;
    font-weight: normal;
    color: #b14935;
}

h3 {
    margin: 10px;
    font-size: 20px;
    font-weight: normal;
}

h4 {
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #7A7A7A;
    margin-top: 30px;
}

a {
    color: #b14935;
}

a:hover {
    color: #cc4533;
}

p {
    margin: 0;
}

.m_t_65 {
    margin-top: 65px;
}

.m_t_85 {
    margin-top: 85px;
}

.login_header {
    text-align: right;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.login_link {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin-right: -5%;
    color: #bb5640;
    height: 13px;
    display: inline-flex;
    text-transform: uppercase;
}

.user_picture {
    display: inline-block;
    height: 40px;
    width: 40px;
    margin-right: -3%;
    background: url(images/user_picture.png) top/40px no-repeat;
}

.user_picture:hover {
    cursor: pointer;
}

.header_text {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #7A7A7A;
}

.header_text > .start {
    padding-top: 20px;
}

.progress_bar {
    position: relative;
    margin: 30px 0 50px;
    height: 12px !important;
    background: #e8e8e8;
    border-radius: 3px;
}

.red_line {
    position: absolute;
    top: -1px;
    height: 14px !important;
    width: 5%;
    background: #bb5640;
    border-radius: 3px;
    transition: width 0.5s;
}

.red_line_20 {
    width: 20%;
    transition: width 0.5s;
}
.red_line_35 {
    width: 35%;
    transition: width 0.5s;
}
.red_line_75 {
    width: 75%;
    transition: width 0.5s;
}
.red_line_100 {
    width: 100%;
    transition: width 0.5s;
}

.no_select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.diagnostics_result_description {
    font: normal normal 14px Roboto serif;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #7A7A7A;
}

.hint_icon {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-left: 6px;
    vertical-align: middle;
    background: url(images/question.png) center/17px no-repeat;
    opacity: 0.55;
}

.hint_icon:hover {
    cursor: pointer;
    opacity: 0.65;
    background: url(images/question_red.png) center/17px no-repeat;
}

.hint {
    position: relative;
    display: inline-block;
}

.hint_text {
    font: normal normal 12px Roboto, serif;
    padding: 27px 11px 17px 17px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #7A7A7A;
}

.hint > .hint_text {
    visibility: hidden;
    width: 193px;
    margin-top: 9px;
    text-align: center;
    border-radius: 6px;
    padding: 26px 0;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.25);
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -90px;
}

.hint:hover .hint_text {
    visibility: visible;
}

.help {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #BB5640;
}

.help_install {
    display: block;
    margin-top: -9px;
}

.button_help {
    margin: 17px 0 30px 0;
    display: flex;
    justify-content: center;
}

.button {
    background: #bb5640;
    font-size: 25px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    padding: 11px 23px;
}

.button:hover {
    background: #cc4533;
    box-shadow: 0 0 5px #000000;
}

.in_progress {
    cursor: progress;
}

.in_progress {
    cursor: progress;
}

.not_agreed_button {
    background: rgba(227, 168, 155, 0.6);
}

.not_agreed_button:hover {
    cursor: default;
    background: rgba(227, 168, 155, 0.6);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.button_label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}

.text {
    text-align: initial;
    font-size: 17px;
    color: #8c8b96;
}

.first_step_text {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    color: #7A7A7A;
}

.install_CA {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    font-size: 19px;
}

.install_CA a {
    margin-top: 5px;
    font-size: 17px;
}

.CA_col {
    display: flex;
    position: relative;
    align-items: center;
    flex: 0 0 40%;
    padding-top: 50px;
    flex-direction: column;
}

.install_CA .first {
    background: url(images/1.png) top/40px no-repeat;
}

.install_CA .second {
    background: url(images/2.png) top/40px no-repeat;
}

.step_label {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #7A7A7A;
}

.arrow {
    flex: 0 0 20%;
    background: url(images/arrow.png) top/130px no-repeat;
    background-position-y: 15px;
}

.CA_col_img {
    margin-top: 10px;
    width: 90px;
    height: 90px;
}

.tr_setup_img {
    background: url(images/tr_setup.png) top/90px no-repeat;
}


.csp_setup_img {
    background: url(images/csp_setup.png) top/90px no-repeat;
}

.lic_agree_text {
    text-align: left;
    margin-top: 10px;
    padding: 20px;
    width: 100%;
    height: 247px;
    overflow-y: scroll;

    background: #FFFFFF;
    border: 1px solid #9C9C9C;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
}

.lic_agree_in:hover {
    cursor: pointer;
}

.lic_agree_in_help {
    display: flex;
    align-items: center;
    padding: 10px;
    text-align: initial;
    font-size: 17px;
    line-height: 16px;
    color: #7A7A7A;
}

.lic_agree_in_help input {
    margin: 0 10px 0 0;
}

.row {
    padding: 20px 80px;
    font-size: 19px;
    text-align: initial;
}

.first_row {
    background: url(images/1.png) left/40px no-repeat;
    background-position-x: 20px;
}

.second_row {
    background: url(images/2.png) left/40px no-repeat;
    background-position-x: 20px;
}

.third_row {
    background: url(images/3.png) left/40px no-repeat;
    background-position-x: 20px;
}

.text_next {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #BB5640;
}

.row_diagnostics {
    margin: 10px;
    padding: 0 30px;
    font-size: 17px;
    text-align: initial;
}

.check {
    background: url(images/correct.png) left/18px no-repeat;
}

.warning {
    background: url(images/warning.png) left/18px no-repeat;
}

.stop {
    background: url(images/stop.png) left/18px no-repeat;
}

.table_link {
    cursor: pointer;
    margin-left: 14px;
    color: #398BC7;
}

.table_link:hover {
    color: #398BC7;
}

.line_arrow {
    overflow: hidden;
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-bottom: 3px;
    margin-left: 7px;
}

.line_arrow.down, .line_arrow.up {
    border-top: 1.3px solid #398bc7;
    border-left: 1.3px solid #398bc7;
}

.line_arrow.down {
    transform: rotate(-144deg) skew(-20deg);
}

.line_arrow.up {
    transform: rotate(35deg) skew(-20deg);
    margin-bottom: 0;
}

.last_step_help {
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certificates {
    padding: 15px;
    height: 116px;
    overflow: auto;
    border: 1px solid #a7a6a6;
    font-size: 12px;
    color: #525862;
}

.certificates_row {
    padding-top: 10px;
    display: flex;
    text-align: initial;
    align-items: center;
}

.row_name {
    flex: 0 0 40%;
    text-align: center;
}

.row_name span {
    color: #557fe2;
    text-decoration: underline;

}
.row_issued {
    flex: 0 0 40%;
    text-align: center;
}
.row_date {
    flex: 0 0 20%;
    text-align: center;
}
.row_path {
    flex: 0 0 20%;
    word-wrap: anywhere;
}

.oddRow {
  background-color: #fafafa;
}

.certificates .head {
    padding: 0;
    text-align: center;
}

.last_step_h2 {
    padding-bottom: 0;
    display: inline-block;
}

.last_step_h3 {
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #353535;
    margin-bottom: 0;
}

.last_step_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.last_step_text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #7A7A7A;
}

.last_step_link {
    display: inline-block;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.footer_text {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.004em;
    color: #7A7A7A;
}
